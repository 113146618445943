import {lighten} from "@theme-ui/color"
import {Utils} from "../utils/utils";

export default {
    useCustomProperties: true,
    colors: {
        white: `${Utils.getSetting('website_color_white', '#FFF')}`,
        black: `${Utils.getSetting('website_color_black', '#15161A')}`,
        headerFontColor: `${Utils.getSetting('website_header_font_color', '#FFF')}`,
        secondaryColor: `${Utils.getSetting('website_color_secondary', '#327F81')}`,
        secondaryDarkColor: `${Utils.getSetting('website_color_secondary_dark', '#068B76')}`,
        secondaryDarkerColor: `${Utils.getSetting('website_color_secondary_darker', '#263533')}`,
        secondaryDarkerColor_70: `rgba(38, 53, 51, ${Utils.getSetting('website_main_content_overlay_opacity', '0.7')})`,
        primaryColor: `${Utils.getSetting('website_color_primary', '#DE3021')}`,
        primaryColorDark: `${Utils.getSetting('website_color_primary_dark', '#9F0102')}`,
        neutral100: `${Utils.getSetting('website_color_neutral', '#FDFAF7')}`,
        neutral300: `${Utils.getSetting('website_color_neutral_dark', '#e8d1b3')}`,
        neutral600: `${Utils.getSetting('website_color_neutral_darker', '#7a4b3e')}`
    },
    fonts: {
        body: `${Utils.getSetting('website_body_font', 'Lato, Helvetica, Arial, sans-serif')}`,
        heading: `${Utils.getSetting('website_heading_font', 'egg_roll, Lato, Helvetica, sans-serif')}`
    },
    fontWeights: {
        body: 400,
        heading: 400,
        bold: 700,
    },
    lineHeight: {
        body: 1.5,
        heading: 1.125
    },
    fontSizes: [12, 14, 16, 20, 32, 56, 80],
    space: [0,4,8,12,16,24,32,40,48,56,64,72,80,88,96,104,112,120,128,136,144],
    breakpoints: ['48rem', '68rem'],
    radii: [4,6,8],
    shadows: {
        small: '0px 4px 16px rgba(0, 0, 0, 0.075)',
        smallHard: '0px 4px 12px rgba(0,0,0,0.2)',
        mediumHard: '0px 16px 48px rgba(0, 0, 0, 0.2)'
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            bg: 'neutral100',
            color: 'black',
        },
        h2: {
            fontSize: 56,
            fontFamily: 'heading',
            fontWeight: 'heading',
            color: 'black',
            mt: 0,
            mb: 24
        }
    },
    layouts: {
        wide: {
            maxWidth: '68rem'
        },
        normal: {
            maxWidth: '56rem'
        }
    },
    buttons: {
        primary: {
            color: 'white',
            bg: 'primaryColor',
            fontSize: [2,1,1],
            py: [3,3,2],
            px: [6,6,5],
            transition: '0.2s ease-in-out all',
            boxShadow: 'shadows.smallHard',
            '&:hover': {
                cursor: 'pointer',
                bg: lighten('primaryColor', 0.05),
                boxShadow: '0px 6px 16px rgba(0,0,0,0.3)'
            }
        },
        secondary: {
            color: 'white',
            bg: `neutral600`,
            fontSize: [2,1,1],
            py: [3,3,2],
            px: [6,6,5],
            transition: '0.2s ease-in-out all',
            boxShadow: `shadows.smallHard`,
            '&:hover': {
                cursor: 'pointer',
                bg: lighten('neutral600', 0.05),
                boxShadow: '0px 6px 16px rgba(0,0,0,0.3)'
            }
        },
        small: {
            color: 'white',
            bg: 'primaryColor',
            fontSize: [2,1,1],
            py: 1,
            px: 4,
            '&:hover': {
                bg: lighten('primaryColor', 0.05),
                cursor: 'pointer'
            }
        }
    }
}