export class Utils {
	static getSetting = (key, defaultValue = null) => {
		let settings = null;

		if (typeof window !== `undefined`
			&& window.hasOwnProperty('SERVER_CONSTANTS')
			&& window.SERVER_CONSTANTS.hasOwnProperty('SETTINGS')) {
			settings = window.SERVER_CONSTANTS.SETTINGS;
		}

		if (settings
				&& settings[key]) {
			return settings[key];
		}

		return defaultValue;
	}
}